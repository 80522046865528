@import "./antd-reduced";
@import "./config/styles/theme";
@import "./config/styles/base";
@import "./config/styles/antd-overrides";
@import "~syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.css";

.continue-btn {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100% !important;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

#root{
  height: 100%;
}

.products {
  width: 324px;
  max-height: 285px;
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0 auto !important;
  justify-content: center;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: auto !important;
  }
}

.st-layout {
  height: 100vh;

  .st-sider {
    background-color: #2f3031;
  }

  .st-content {
    background-color: #f5f8fc;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .terms-conditions {
    .inner-container {
      height: calc(100% - 42px);
    }
  }
  .TACContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
/*Responsive css*/
@media (max-width: 768px) {
  .accounts-right-block {
    display: none;
  }
  .accounts-left-block {
    margin: auto;
    box-shadow: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    min-width: 320px !important;
    width: 320px !important;
    height: calc(100vh);
    overflow: inherit !important;
    padding-top: 20px !important;
    .app-language {
      margin: 10px 0px 10px 0 !important;
    }
    .signin-btn {
      max-width: 100% !important;
      min-width: 100% !important;
      position: relative;
      top: -40px;
      margin-bottom: 0px !important;
    }
    .signin-pwd {
      margin-bottom: 20px;
    }
    .login-form-keep-loggedIn {
      position: relative;
      top: -54px;
    }
    .header {
      font-size: 16px !important;
    }
    .reset-header {
      margin-bottom: 12px !important;
    }
    .voucher-btns {
      margin-top: 0px !important;
    }
    .account-details {
      padding: 20px 10px !important;
    }
    .account-img {
      padding-top: 10px !important;
    }
    .start-btn {
      margin-top: 24px !important;
    }

    .continue-btn {
      margin-top: 20px !important;
      width: 100% !important;
    }
    .mobile-number {
      margin-bottom: 20px;
    }
  }
  // Model
  .syngenta-ant-modal-wrap {
    display: flex;
    align-items: flex-end;
    .syngenta-ant-modal {
      max-width: 100% !important;
      width: 100% !important;
      top: unset;
      margin: 0;
      padding: 0;
      .syngenta-ant-modal-content {
        border-radius: 0px !important;
      }
    }
  }
  .terms-conditions {
    padding: 20px;
    width: 100%;
    height: 100vh;
    .fQsatj,
    .custom-layout {
      padding-top: 0 !important;
      height: auto !important;
    }
    .bjMbRe {
      margin: 0px auto 0px;
    }
    .TACPrivacyPolicy {
      font-size: 16px !important;
      // margin-bottom: 17px !important;
    }
    .agree-privacy {
      padding: 0 !important;
      .t-c {
        flex-direction: column;
        height: calc(100vh - 220px) !important;
        .syngenta-ant-card.card-wrapper-container {
          max-width: 100% !important;
          width: 100% !important;
          height: auto !important;
          margin-right: 0 !important;
          margin-bottom: 5px;
          .syngenta-ant-card-body {
            overflow: hidden !important;
            border: 0;
          }
        }
        .TACText {
          width: 100% !important;
          max-width: 100% !important;
          font-size: 12px !important;
        }
      }
      .t-c-btns {
        div {
          button {
            left: 0px !important;
            right: 0px !important;
          }
        }
      }
    }
  }
  .card-wrapper-container {
    .t-c-timeline {
      position: relative;
      .syngenta-ant-timeline {
        width: calc(100% - 18px);
        height: 80px;
        display: flex;
        .timeline-li {
          width: 100%;
          background: transparent;
          .syngenta-ant-timeline-item.timeline-custom-item {
            margin-left: 0 !important;
            text-align: left;
            height: 33px;
            .syngenta-ant-timeline-item-tail {
              position: absolute;
              margin-left: 14px;
              width: calc(100% - 14px);
              height: 2px !important;
              background: #e8e8e8;
            }
            .syngenta-ant-timeline-item-head {
              top: 16px !important;
            }
            .syngenta-ant-timeline-item-content {
              top: 30px !important;
              left: 0;
              margin: 0;
              padding: 0;
              word-break: break-word;
            }
          }
        }
        .syngenta-ant-timeline-item-last {
          width: 0px !important;
          .syngenta-ant-timeline-item {
            .syngenta-ant-timeline-item-tail {
              display: none !important;
            }
          }
          .offerFont {
            width: 0px;
          }
        }
      }
    }
  }
  .BNtsP {
    img {
      margin-top: 0px !important;
    }
    .title {
      font-size: 16px !important;
    }
  }
  .warning-msg {
    margin: 0px !important;
  }

  .TACContainer {
    overflow: hidden;
  }

  .offerFont {
    width: 70px;
    font-size: 12px !important;
    visibility: hidden !important;
    &.active {
      visibility: visible !important;
      position: absolute;
      width: calc(100vw - 45px) !important;
      // width: 100% !important;
      left: 0;
      display: flex;
      justify-content: space-between;
      // padding: 0 22px;
      z-index: 999;
    }
    span {
      &:nth-child(2) {
        // margin-right: 0px !important;
        margin-top: 5px;
      }
    }
  }
  /*Tooltip css*/
  .syngenta-ant-tooltip-placement-rightTop {
    bottom: calc(100% - 592px);
    left: 42% !important;
    top: auto !important;
    .syngenta-ant-tooltip-arrow {
      left: 92.5%;
      top: -13.6px !important;
      transform: rotate(90deg);
      &::before {
        width: 10px;
        height: 10px;
        transform: translateX(6.53553391px) rotate(46deg) !important;
      }
    }
  }
}
@media (max-width: 320px) {
  .accounts-left-block {
    min-width: 280px !important;
    width: 280px !important;
  }
}

@media (max-width: 500px) {
  .BNtsP {
    img {
      width: 100% !important;
    }
  }
}

/* Portrait and Landscape */
@media (max-width: 480px) and (min-width: 320px) {
  .accounts-left-block {
    box-shadow: none !important;
    min-width: 100% !important;
    width: 100% !important;
    height: calc(100vh);
    overflow: inherit !important;
    padding: 20px !important;
  }
  .syngenta-ant-card-body {
    h3 {
      margin: 2px 0 !important;
    }
  }
  // .t-c-timeline {
  //   .syngenta-ant-timeline {
  //     width: calc(100% - 18px);
  //   }
  // }
  .syngenta-ant-tooltip-placement-rightTop {
    left: 34% !important;
  }
}

@media (max-width: 660px) and (min-width: 481px) {
  .syngenta-ant-tooltip-placement-rightTop {
    left: 40% !important;
  }
}
@media (max-width: 414px) and (min-width: 375px) {
  .syngenta-ant-tooltip-placement-rightTop {
    left: 38% !important;
  }
}

@media (max-width: 320px) {
  .accounts-left-block {
    .login-form-keep-loggedIn {
      position: relative;
      top: -74px;
    }
    .products {
      width: 280px !important;
    }
  }
  .syngenta-ant-tooltip-placement-rightTop {
    left: 27% !important;
  }
}
@media (max-width: 1024px) {
  .t-c-btns {
    div {
      button {
        left: 0px !important;
        right: 0px !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .accounts-left-block {
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
}

@media (max-width: 768px) {
  .offerFont.mobile {
    display: flex !important;
    margin: 12px 0px;
  }
  .desktop {
    display: none !important;
  }
}

.offerFont.mobile {
  display: none;
}

.loading-container {
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .illustration-position {
    margin-top: 28px;
  }
  .loading-text {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #00004b;
    margin: 12px 0 16px 0;
  }
  .spinner {
    color: #14803c;
    margin-top: 16px;
  }
}

// CookieBot CSS Override
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentTitle {
  font-family: "Noto Sans", sans-serif !important;
}

// Cookiebot CSS
@media (min-width: 813px) {
  div#CybotCookiebotDialogBody {
    margin: 20px 140px !important;
    max-width: unset;
    padding-right: 0;
    height: 110px;
  }

  div#CybotCookiebotDialogDetailBody {
    max-width: 970px;
    padding-left: 70px;
  }

  div#CybotCookiebotDialogPoweredbyLink {
    margin-top: 0 !important;
  }

  div#CybotCookiebotDialogBodyContent {
    padding: 0 0 0 80px !important;
  }

  // title font
  h2#CybotCookiebotDialogBodyContentTitle {
    font-size: 16px;
    line-height: 24px;
  }

  div#CybotCookiebotDialogBodyContentText {
    font-size: 12px;
    line-height: 20px;
  }

  // Allow only the required cookies
  a#CybotCookiebotDialogBodyButtonDecline {
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 218px !important;
    height: 24px;
    right: 308px;
    bottom: 20px;
    border-radius: 4px;
    border: none;
    padding: 4px 15px 2px 15px;
    text-align: center;
    font-family: Noto Sans, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 20px !important;
    background: #f3f4f6 !important;
    color: black !important;
    &:hover {
      color: black;
    }
    overflow: hidden;
    white-space: nowrap !important;
    display: block !important;
    text-overflow: ellipsis;
  }

  //  Allow all cookies
  a#CybotCookiebotDialogBodyButtonAccept {
    // display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 122px !important;
    height: 24px;
    right: 140px;
    bottom: 20px;
    background: rgb(20, 128, 60);
    border-radius: 4px;
    border: none;
    padding: 4px 15px 2px 15px;
    font-family: Noto Sans, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 20px !important;
    overflow: hidden;
    white-space: nowrap !important;
    display: block !important;
    text-overflow: ellipsis;
  }

  // See more details
  a#CybotCookiebotDialogBodyButtonDetails {
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 124px;
    height: 24px;
    left: 220px;
    bottom: 20px;
    background: #f3f4f6 !important;
    border-radius: 4px;
    font-size: 14px !important;
    font-family: Noto Sans, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    text-decoration: none !important;
    overflow: hidden;
    white-space: nowrap !important;
    display: block !important;
    text-overflow: ellipsis;
  }

  div#CybotCookiebotDialogDetail {
    margin-bottom: 50px;
  }
}

// CSS for Mobile / iPad view
@media (max-width: 812px) {
  div#CybotCookiebotDialogBody {
    margin: 20px !important;
  }

  // logo
  div#CybotCookiebotDialogPoweredbyLink {
    display: block !important;
    position: relative;
  }

  // content
  div#CybotCookiebotDialogBodyContent {
    padding: 0px 0px 0px 10px !important;
  }

  // Button container
  div#CybotCookiebotDialogBodyButtons {
    padding-left: 0;
  }

  // Reject
  a#CybotCookiebotDialogBodyButtonDecline {
    border-radius: 4px;
    border: none;
    font-weight: normal;
    font-style: normal;
    width: 100% !important;
    margin: 8px 0px 0px 0px !important;
    background: #f3f4f6 !important;
    color: black !important;
    padding-top:10px !important;
    padding-bottom: 10px !important;
  }

  // Accept
  a#CybotCookiebotDialogBodyButtonAccept {
    border-radius: 4px;
    border: none;
    background: rgb(20, 128, 60);
    font-weight: normal;
    font-style: normal;
    width: 100% !important;
    margin: 8px 0px 0px 0px !important;
    padding: 10px 0 !important;
  }

  // See more
  a#CybotCookiebotDialogBodyButtonDetails {
    background: #f3f4f6 !important;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 !important;
    border: unset !important;
    font-style: normal;
    font-weight: normal;
    width: 100% !important;
    text-align: center !important;
    text-decoration: none !important;
    position: relative;
  }
}
.warning-msg-lable {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 5px 0px;
}

.title-bar {
  color: #14151c;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 17px;
  letter-spacing: -0.0075em;
  line-height: 28px;
}

.sub-title {
  color: #14151c;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.error-message {
  font-size: 12px;
  line-height: 20px;
  color: #eb4b4b;
  text-align: left;
  margin-left: 2px;
  min-height: 22px;
}

.accounts-lable {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  color: #14151C;
  font-weight: 400;
}

.accounts-button {
  width: 214px;
  height: 40px;
  margin: 0px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  float: right;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #14803c;
  &.secondary-color {
    color: #2f3031;
  }
  &.primary-color {
  }
  &.border-primary {
  }
  &.background-color-primary {
  }
  &.syngenta-ant-btn[disabled]:not(&.tnc-button) {
    background-color: #f3f4f6;
    color: #c2c7d0;
    border-color: #dfe2e7;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: sub;
    max-width: 100%;
  }
}
.accounts-input {
  background-color: #f3f4f6;
  border: 1px solid #c2c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #14151c;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
  margin: 2px 0 22px;
  height: 40px;
  .syngenta-ant-input-affix-wrapper:hover
    .syngenta-ant-input:not(.syngenta-ant-input-disabled) {
    border-color: #c2c7d0 !important;
    border-right-width: 1px !important;
  }
  .syngenta-ant-input-affix-wrapper:hover {
    border-color: #c2c7d0 !important;
  }
  .syngenta-ant-input-affix-wrapper:hover .syngenta-ant-input {
    border-color: #c2c7d0 !important;
  }
  .syngenta-ant-input:hover,
  .syngenta-ant-input:focus {
    border-color: #c2c7d0 !important;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 0px !important;
  }
  &.error {
    border: 1px solid #ff4d4f;
    &:hover {
      border-color: #ff4d4f;
    }
    &:focus {
      border-color: #ff4d4f;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    }
  }
}

.accounts-cancel-button {
  background: #dfe2e7;
  border-color: #dfe2e7;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151c;
  width: 100%;
  &:hover,
  &:active,
  &:focus {
    color: #14151c;
    background-color: #dfe2e7;
    border-color: #dfe2e7;
  }
}

.accounts-spin {
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222222ab;
  z-index: 9999;

  /*********Overrie css classes**/
  .syngenta-ant-spin-dot {
    position: absolute;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
    top: 45% !important;
  }
  .syngenta-ant-spin.ant-spin-show-text .syngenta-ant-spin-text {
    display: block;
    top: 52%;
    position: absolute;
    left: 2%;
    right: 0;
  }
}

.accounts-subtitle {
  color: #14151c;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.root-inner {
  font-family: Noto Sans, sans-serif;
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
}

.accounts-checkbox {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #14151c;
  float: left;
  .syngenta-ant-checkbox .syngenta-ant-checkbox-inner {
    border: 1px solid #c2c7d0;
    height: 18px;
    width: 18px;
  }
  .syngenta-ant-checkbox-checked .syngenta-ant-checkbox-inner {
    border: 1px solid #14803c;
    background-color: #14803c;
    height: 18px;
    width: 18px;
  }
  span:last-child {
    padding-right: 0;
  }
}

.syngenta-ant-btn > .syngenta-ant-btn-loading-icon .anticon {
  padding-right: 0px !important;
  margin-right: 8px;
}

.syngenta-ant-form-item-label > label {
  font-size: 14px;
  height: 22px;
  margin: 0;
  color: #696f88;
}

//common-styling
.syngenta-ant-form-item-control {
  line-height: 35px;
}

.syngenta-ant-input-password {
  background-color: #f3f4f6;
  border: 1px solid #c2c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 2px 0 5px;
  height: 40px;
  color: #14151c;
  .syngenta-ant-input-affix-wrapper:hover
    .syngenta-ant-input:not(.syngenta-ant-input-disabled) {
    border-color: #f3f4f6 !important;
    border-right-width: 1px !important;
  }
  .syngenta-ant-input-affix-wrapper:hover {
    border-color: #f3f4f6 !important;
    border-right-width: 1px !important;
  }
  .syngenta-ant-input-affix-wrapper:hover .syngenta-ant-input {
    border-color: #f3f4f6 !important;
  }
  .syngenta-ant-input:hover,
  .syngenta-ant-input:focus {
    border-color: #c2c7d0 !important;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 0px !important;
  }
  .syngenta-ant-input-suffix {
    color: #707374;
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 90%;
    top: 0%;
    transform: none;
    z-index: 0;
  }
  .syngenta-ant-input-suffix i {
    color: #696f88;
  }
  &.syngenta-ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
}

.error-css {
  .syngenta-ant-input-affix-wrapper:not(:focus-within),
  .syngenta-ant-input-affix-wrapper:not(:focus-within) {
    border: 1px solid #ff4d4f;
    &:hover {
      border: 1px solid #ff4d4f;
    }
  }
}

@ant-prefix: syngenta-ant;@media-default: ~'only screen and (max-width:1366px)';@media-large: ~'only screen and (min-width:1366px)';@primary-color: #00b5d8;@layout-body-background: #e5e5e5;@heading-color: #707374;@label-color: #707374;@input-bg: #f3f4f6;@input-border-color: #c2c7d0;@input-color: #14151c;@select-dropdown-bg: #f5f8fc;@select-background: #f5f8fc;@border-color-base: @input-border-color;@page-header-back-color: #00a0be;@menu-bg: #f5f8fc;@menu-item-active-bg: #ffffff;@tabs-card-active-color: #749204;@tabs-card-height: 44px;@tabs-hover-color: #749204;@tabs-active-color: #749204;@tabs-card-head-background: #f0f3f7;@modal-footer-border-color-split: #ffffff;@modal-heading-color: #eb4b4b;@collapse-header-bg: #f2f4f6;@font-family: 'Noto Sans', sans-serif, Arial;@text-weight: 400;@text-bold-weight: 500;@text-light-weight: 300;@text-color: #2f3031;@text-color-secondary: #707374;@btn-default-color: #2f3031;@btn-default-bg: #f2f4f6;@btn-default-border: #f0f3f7;@slider-track-background-color: #00b5d8;@slider-track-background-color-hover: #00b5d8;@slider-handle-color: #00b5d8;@slider-handle-color-hover: #00b5d8;@slider-handle-color-focus: #00b5d8;@slider-handle-color-focus-shadow: fade(#00b5d8, 20%);@slider-handle-color-tooltip-open: #00b5d8;@slider-dot-border-color-active: tint(#00b5d8, 50%);