@import "~antd/lib/spin/style/index.less";
@import "~antd/lib/icon/style/index";
@import "~antd/lib/checkbox/style/index.less";
@import "~antd/lib/radio/style/index.less";
@import "~antd/lib/tooltip/style/index.less";
@import "~antd/lib/dropdown/style/index";
@import "~antd/lib/modal/style/index";
@import "~antd/lib/menu/style/index";
@import "~antd/lib/button/style/index";
@import "~antd/lib/form/style/index.less";
@import "~antd/lib/progress/style/index";
@import "~antd/lib/input/style/index";
@import "~antd/lib/style/core/index";
@import "~antd/lib/timeline/style/index.less";
@import "~antd/lib/divider/style/index.less";
@import '~antd/lib/select/style/index';
@import '~antd/lib/layout/style/index';
@import '~antd/lib/card/style/index';
@import '~antd/lib/space/style/index';
@import '~antd/lib/steps/style/index';

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

[ant-click-animating="true"],
[ant-click-animating-without-extra-node="true"] {
  position: relative;
}

.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
